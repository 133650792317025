import { createRouter, createWebHistory } from "vue-router";
import AOS from "aos";
import 'aos/dist/aos.css';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) {
      return {
        // could also be
        // el: document.getElementById('main'),
        el: to.hash,
        // 10px above the element
        top: 100,
      }
  }
    return { top: 0 };
  },
  routes: [ 
    {
      path: "/",
      name: "home",
      component: () => import("@/views/index.vue"),
      
        
       
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/views/about.vue"),
      
        
       
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: () => import("@/views/campaigns/index.vue"),
      
        
       
    },
    {
      path: "/campaigns/:id",
      name: "campaigns-details",
      component: () => import("@/views/campaigns/details.vue"),
      
        
       
    },
    {
      path: "/blogs",
      name: "blogs",
      component: () => import("@/views/blogs/index.vue"),
      
        
       
    },
    {
      path: "/blogs/:id",
      name: "blogs-details",
      component: () => import("@/views/blogs/details.vue"),
      
        
       
    },
    {
      path: "/clients",
      name: "clients",
      component: () => import("@/views/clients.vue"),
      
        
       
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/views/contact.vue"),
      
        
       
    },
    {
      path: "/consultation",
      name: "consultation",
      component: () => import("@/views/consultation.vue"),
      
        
       
    },
    {
      path: "/policy",
      name: "policy",
      component: () => import("@/views/policy.vue"),
      
        
       
    },
    {
      path: "/request-consultation",
      name: "request-consultation",
      component: () => import("@/views/rules.vue"),
      
        
       
    },
    {
      path: "/:pathMatch(.*)*",
      meta: {
        layout: "full",
      },
      name: "not-found",
      component: () => import("@/views/index.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => { 
next();

AOS.init({
   // Global settings:
   disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
   startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
   initClassName: 'aos-init', // class applied after initialization
   animatedClassName: 'aos-animate', // class applied on animation
   useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
   disableMutationObserver: false, // disables automatic mutations' detections (advanced)
   debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
   throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
   
 
   // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
   offset: 30, // offset (in px) from the original trigger point
   delay: 0, // values from 0 to 3000, with step 50ms
   duration: 1000, // values from 0 to 3000, with step 50ms
   easing: 'ease', // default easing for AOS animations
   once: false, // whether animation should happen only once - while scrolling down
   mirror: true, // whether elements should animate out while scrolling past them
   anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
 
}); // Initialize AOS
});

router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"],  },
}).href; // '/not/found'
export default router;
