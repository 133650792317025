import { defineStore } from "pinia";
// import events from '@/fake'
export const useContactStore = defineStore({
  id: "contact",
  state: () => ({
    contact: null,
    faqs: null,
  }),
  getters: {
  },
  actions: {
    async _getContactInfo() {
      return await this.$http
        .get(`/landing/contact`)
        .then((response) => {
          this.contact = response.data.contact;
        })
        .catch((err) => {
          throw err;
        });
    },
    async _contactUs(data) {
      this.$wameedLoading("contact-btn", true);
      return await this.$http
        .post(`/landing/contact-us`, data)
        .then((response) => {
          this.$wameedLoading("contact-btn", false);
          this.$wameedNotify({
            title:  'تم إرسال الرسالة بنجاح',
            type: "success",
            api:true
          });
          return true
        })
        .catch((err) => {
          this.$wameedLoading("contact-btn", false);
          throw err;
        });
    },
    async _consultation(data) {
      this.$wameedLoading("consultation-btn", true);
      return await this.$http
        .post(`/landing/consultation`, data)
        .then((response) => {
          this.$wameedLoading("consultation-btn", false);
          this.$wameedNotify({
            title:  'تم إرسال طلب الإستشارة بنجاح',
            type: "success",
            api:true
          });
          return true
        })
        .catch((err) => {
          this.$wameedLoading("consultation-btn", false);
          throw err;
        });
    },
    async _getFaqs() {
      this.$wameedLoading("faqs-content", true);
      return await this.$http
        .get(`/landing/faqs`)
        .then((response) => {
      this.$wameedLoading("faqs-content", false);
      this.faqs = response.data.faqs;
        })
        .catch((err) => {
          this.$wameedLoading("faqs-content", false);
          throw err;
        });
    },
  },
});
