<template>
  <!--begin::Header-->
  <header class="header " :class="{
    'header__details': $router.currentRoute.value.name != 'home' && $router.currentRoute.value.name != 'not-found'
  }">
    <div class="overlay"></div>
    <div class="mobile-menu hide-md">
      <div class="container">
        <div class="mobile-menu__wrapper">
          <div class="mobile-menu__icons row align-center justify-between">
            <div class="col hide-md">
              <x-mark class="header__close-icon" />

            </div>
            <div class="col">
              <img src="../assets/images/logo.png" alt="moather logo" class="header__logo" />
            </div>
          </div>

          <ul class="mobile-menu__list row flexColumn no-margin align-center justify-center">
            <li :class="{ 'active': $router.currentRoute.value.name == 'home' }"><router-link :to="{ name: 'home' }"
                @click="closeMenu()">الرئيسية</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'about' }"><router-link :to="{ name: 'about' }"
                @click="closeMenu()">من نحن</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'campaigns' }"><router-link
                :to="{ name: 'campaigns' }" @click="closeMenu()">الحملات</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'blogs' }"><router-link :to="{ name: 'blogs' }"
                @click="closeMenu()"> المدونة</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'clients' }"><router-link
                :to="{ name: 'clients' }" @click="closeMenu()">عملائنا</router-link></li>
            <li><router-link :to="{ name: 'consultation' }" @click="closeMenu()">طلب إستشارة</router-link></li>
          </ul>

          <div class=" ">
            <router-link :to="{ name: 'contact' }" class="btn btn__main" @click="closeMenu()">تواصل معنا</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">

      <!-- Desktop -->
      <div class="row align-center justify-between">
        <div class="col hide-md">

          <menu-icon alt="moather menu" class="header__menu-icon is-dark" />
          <menu-white alt="moather menu" class="header__menu-icon is-white" />

        </div>
        <div class="col">
          <img src="../assets/images/logo.png" alt="moather logo" class="header__logo is-dark" />
          <img src="../assets/images/logo-white.png" alt="moather logo" class="header__logo is-white" />
        </div>
        <div class="col flex-1 show-md">
          <ul class="header__menu row no-margin align-center justify-center">
            <li :class="{ 'active': $router.currentRoute.value.name == 'home' }"><router-link
                :to="{ name: 'home' }">الرئيسية</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'about' }"><router-link
                :to="{ name: 'about' }">من
                نحن</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'campaigns' }"><router-link
                :to="{ name: 'campaigns' }">الحملات</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'blogs' }"><router-link :to="{ name: 'blogs' }">
                المدونة</router-link></li>
            <li :class="{ 'active': $router.currentRoute.value.name == 'clients' }"><router-link
                :to="{ name: 'clients' }">عملائنا</router-link></li>
            <li><router-link :to="{ name: 'consultation' }">طلب إستشارة</router-link></li>
          </ul>
        </div>
        <div class="col show-md">
          <router-link :to="{ name: 'contact' }" class="btn btn__main sm-in-mobile">تواصل معنا</router-link>
        </div>
      </div>
    </div>
  </header>
  <!--end::Header-->
</template>

<script>
export default {
  data: () => ({
    toggleLang: false,
    activeMenu: false
  }),
  computed: {
  },
  mounted() {

    //header
    var className = "scrolled";
    var scrollTrigger = 20;

    window.onscroll = function () {
      // We add pageYOffset for compatibility with IE.
      if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
        document.querySelector(".header").classList.add(className);
      } else {
        document.querySelector(".header").classList.remove(className);

      }
    };

    document.querySelectorAll('.header__menu-icon').forEach(ele => {
      ele.addEventListener('click', () => {
        document.querySelector('.mobile-menu').classList.add('show')
        document.querySelector('.overlay').classList.add('show')
      })
    })

    document.querySelector('.header__close-icon').addEventListener('click', () => {
      document.querySelector('.mobile-menu').classList.remove('show')
      document.querySelector('.overlay').classList.remove('show')
    })

    document.querySelector('.overlay').addEventListener('click', () => {
      document.querySelector('.mobile-menu').classList.remove('show')
      document.querySelector('.overlay').classList.remove('show')
    })
  },
  methods: {
    closeMenu() {
      document.querySelector('.mobile-menu').classList.remove('show')
      document.querySelector('.overlay').classList.remove('show')

    },

    changeLanguage(lang) {
      this.$router.push({
        path:
          "/" + lang + this.$route.path.substring(3, this.$route.path.length),
      });
      this.$nextTick(() => {
        this.toggleLang = !this.toggleLang;
        this.$nuxt.$loading.start();
        setTimeout(() => {
          this.$nuxt.$loading.finish();
          this.showcontent = true;
        }, 3000);
      });
    },
  },
};
</script>